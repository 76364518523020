import React from 'react';
import axios from 'axios';

import './LinkedAccounts.scss';
import trashIcon from '../../constants/images/trash.svg';
import editIcon from '../../constants/images/edit.svg';
import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

import AuthHelperMethods from '../Login/AuthHelperMethods';

class LinkedAccounts extends React.Component {

    /**
     * Creates an instance of LinkedAccounts.
     * @param {*} props
     * @memberof LinkedAccounts
     */
    constructor(props) {
        super(props);

        this.state = {
            linkedAccounts: {},
            newLinkedAccount: false,
            newLinkedAccountScroll: false,
            newLinkedAccountLocalValue: "",
            newLinkedAccountLinkedValue: "",
            newLinkedAccountDescription: "",
            updateLinkedAccount: false,
            updateLinkedAccountLocalValue: "",
            updateLinkedAccountLinkedValue: "",
            updateLinkedAccountDescription: "",
            removeLinkedAccount: false,
            removeLinkedAccountLocalValue: "",
            removeLinkedAccountLinkedValue: "",
            pending: false,
            error: false,
            success: false,
        };

        this.Auth = new AuthHelperMethods();

        this.tableBody = React.createRef();
        this.successMessage = React.createRef();
        this.successTimeout = null;
    }

    /**
     * React method triggered when component is mounted to DOM
     * Performs request to query for linked accounts
     *
     * @memberof LinkedAccounts
     */
    componentDidMount() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/accounts`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };

        axios.get(requestPath, options)
                .then(response => {
                    const accounts = response.data;
                    this.setState({
                        linkedAccounts: accounts,
                    });
                })
                .catch(err => {
                    const errMsg = "Could not retrieve account information";
                    this.setState({
                        error: errMsg,
                        success: false,
                        pending: false,
                    });
                    console.log(err);
                });
    }

    /**
     * Cancels any timeout for the success message fade
     *
     * @memberof LinkedAccounts
     */
    componentWillUnmount() {
        if (this.successTimeout) {
            clearTimeout(this.successTimeout);
        }
    }

    /**
     * Scrolls to the bottom of the list to maintain visibility of new account row and fades success message
     *
     * @memberof LinkedAccounts
     */
    componentDidUpdate() {
        if(this.state.newAccountScroll) {
            this.tableBody.current.scrollTo(0, this.tableBody.current.scrollHeight);
            this.setState({
                newLinkedAccountScroll: false,
            });
        }

        if(this.state.success) {
            this.successTimeout = setTimeout(() => {
                this.successMessage.current.style.opacity = 0; 
                this.successTimeout = null;
            }, 5000);
        }
    }

    /**
     * Helper method to trigger input box to enter new linked account details
     *
     * @param {*} event A click event
     * @memberof LinkedAccounts
     */
    addNewLinkedAccount(event) {
        this.setState({
            newLinkedAccount: "new-row",
            newLinkedAccountScroll: true,
            newLinkedAccountLocalValue: "",
            newLinkedAccountLinkedValue: "",
            newLinkedAccountDescription: "",
        });
    }

    /**
     * Helper method to trigger input boxes to update linked account
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    setEditAccount(event) {
        let account = event.target.getAttribute("updateaccount");
        let username = event.target.getAttribute("username");
        let linkedUsername = event.target.getAttribute("linkedusername");
        let description = event.target.getAttribute("description");

        this.setState({
            updateLinkedAccount: account,
            updateLinkedAccountLocalValue: username || "",
            updateLinkedAccountLinkedValue: linkedUsername || "",
            updateLinkedAccountDescription: description || "",
        });
    }

    /**
     * Helper method to cancel edit input boxes
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    cancelEditAccount(event) {
        this.setState({
            updateLinkedAccount: false,
            updateLinkedAccountLocalValue: "",
            updateLinkedAccountLinkedValue: "",
            updateLinkedAccountDescription: "",
        });
    }

    /**
     * Helper method to cancel add input boxes
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    cancelAddAccount(event) {
        this.setState({
            newLinkedAccount: false,
            newLinkedAccountScroll: false,
            newLinkedAccountLocalValue: "",
            newLinkedAccountLinkedValue: "",
            newLinkedAccountDescription: "",
        });
    }

    /**
     * Helper method to trigger modal to delete linked account
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    setRemoveAccount(event){
        if (this.state.removeLinkedAccount) {
            this.setState({
                removeLinkedAccount: false,
                removeLinkedAccountLocalValue: "",
                removeLinkedAccountLinkedValue: "",
            });    
        } else {
            const account = event.target.getAttribute("removeaccount");
            const username = event.target.getAttribute("username");
            const linkedUsername = event.target.getAttribute("linkedusername");
            this.setState({
                removeLinkedAccount: account,
                removeLinkedAccountLocalValue: username,
                removeLinkedAccountLinkedValue: linkedUsername,
            });
        }
    }

    /**
     * Request to add new linked account
     *
     * @memberof LinkedAccounts
     */
    addNewLinkedAccountRequest() {
        let currentLinkedAccounts = this.state.newLinkedAccountLocalValue in this.state.linkedAccounts ? this.state.linkedAccounts[this.state.newLinkedAccountLocalValue] : [];
        let existingAccountIndex = currentLinkedAccounts.findIndex((a) => {
            return a.linkedUsername === this.state.newLinkedAccountLinkedValue;
        });
        if (existingAccountIndex !== -1) {
            // already exists!
            this.setState({
                error: "Account already exists!",
                success: false,
            });
        } else {
            let newLinkedAccount = {
                username: this.state.newLinkedAccountLocalValue,
                linkedUsername: this.state.newLinkedAccountLinkedValue,
                description: this.state.newLinkedAccountDescription,
            };
            currentLinkedAccounts.push(newLinkedAccount);

            this.setState({
                pending: true,
                success: false,
                error: false,
            });
            const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/accounts/${this.state.newLinkedAccountLocalValue}`;
            const accessString = this.Auth.getToken();
            const options = {
                params: {},
                headers: {
                    "Authorization": `Bearer ${accessString}`,
                    "Content-Type": "application/json",
                },
            };

            axios.post(requestPath, currentLinkedAccounts, options)
                    .then(response => {
                        let updatedAccounts = Object.assign({}, this.state.linkedAccounts);
                        updatedAccounts[this.state.newLinkedAccountLocalValue] = currentLinkedAccounts;
                        this.setState({
                            pending: false,
                            newLinkedAccount: false,
                            newLinkedAccountScroll: false,
                            newLinkedAccountLocalValue: "",
                            newLinkedAccountLinkedValue: "",
                            newLinkedAccountDescription: "",
                            linkedAccounts: updatedAccounts,
                            success: true,
                            error: false,
                        });
                    })
                    .catch(err => {
                        const errMsg = `Cannot link account ${this.state.newLinkedAccountLinkedValue} to ${this.state.newLinkedAccountLocalValue}`;
                        this.setState({
                            error: errMsg,
                            success: false,
                            pending: false,
                        });
                        console.log(err);
                    });
        }
    }

    /**
     * Request to edit linked account
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    editLinkedAccountRequest(event) {
        let oldLinkedUsername = event.target.linkedUsername;
        let currentLinkedAccounts = this.state.linkedAccounts[this.state.updateLinkedAccountLocalValue];
        
        let oldIndex = currentLinkedAccounts.findIndex((a) => {
            return a.linkedUsername === oldLinkedUsername;
        });
        currentLinkedAccounts.splice(oldIndex, 1);
        
        let updatedLinkedAccount = {
            username: this.state.updateLinkedAccountLocalValue,
            linkedUsername: this.state.updateLinkedAccountLinkedValue,
            description: this.state.updateLinkedAccountDescription,
        };
        currentLinkedAccounts.push(updatedLinkedAccount);

        this.setState({
            pending: true,
            success: false,
            error: false,
        });
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/accounts/${this.state.updateLinkedAccountLocalValue}`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                "Authorization": `Bearer ${accessString}`,
            },
        };

        axios.post(requestPath, currentLinkedAccounts, options)
                .then(response => {
                    let updatedAccounts = Object.assign({}, this.state.linkedAccounts);
                    updatedAccounts[this.state.updateLinkedAccountLocalValue] = currentLinkedAccounts;
                    this.setState({
                        pending: false,
                        updateLinkedAccount: false,
                        updateLinkedAccountLocalValue: "",
                        updateLinkedAccountLinkedValue: "",
                        updateLinkedAccountDescription: "",
                        linkedAccounts: updatedAccounts,
                        success: true,
                        error: false,
                    });
                })
                .catch(err => {
                    const errMsg = `Cannot update linked account ${this.state.updateLinkedAccountLinkedValue} to ${this.state.updateLinkedAccountLocalValue}`;
                    this.setState({
                        error: errMsg,
                        success: false,
                        pending: false,
                    });
                    console.log(err);
                });
    }

    /**
     * Request to delete linked account
     *
     * @memberof LinkedAccounts
     */
    deleteLinkedAccountRequest() {
        let currentLinkedAccounts = this.state.linkedAccounts[this.state.removeLinkedAccountLocalValue].slice();
        
        let oldIndex = currentLinkedAccounts.findIndex((a) => {
            return a.linkedUsername === this.state.removeLinkedAccountLocalValue && a.linkedUsername === this.state.removeLinkedAccountLinkedValue;
        });
        currentLinkedAccounts.splice(oldIndex, 1);

        this.setState({
            pending: true,
            success: false,
            error: false,
            removeLinkedAccount: false,
        });
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/accounts/${this.state.removeLinkedAccountLocalValue}`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                "Authorization": `Bearer ${accessString}`,
                "Content-Type": "application/json",
            },
        };

        axios.post(requestPath, currentLinkedAccounts, options)
                .then(response => {
                    let updatedAccounts = Object.assign({}, this.state.linkedAccounts);
                    updatedAccounts[this.state.removeLinkedAccountLocalValue] = currentLinkedAccounts;
                    this.setState({
                        pending: false,
                        removeLinkedAccount: false,
                        removeLinkedAccountLocalValue: "",
                        removeLinkedAccountLinkedValue: "",
                        linkedAccounts: updatedAccounts,
                        success: true,
                        error: false,
                    });
                })
                .catch(err => {
                    const errMsg = `Cannot delete linked account ${this.state.removeLinkedAccountLinkedValue} to ${this.state.removeLinkedAccountLocalValue}`;
                    this.setState({
                        error: errMsg,
                        success: false,
                        pending: false,
                    });
                    console.log(err);
                });
        
    }

    /**
     * Event handler for the save button
     * Sends request to update linked accounts for current user based on added/removed account actions by user
     *
     * @memberof LinkedAccounts
     */
    updateLinkedAccounts(event) {
        event.preventDefault();
        if (this.state.newLinkedAccount) {
            this.addNewLinkedAccountRequest();
        }
        else if (this.state.updateLinkedAccount){
            this.editLinkedAccountRequest(event);
        }
        else if (this.state.removeLinkedAccount) {
            this.deleteLinkedAccountRequest();
        }
    }


    /**
     * Event handler for new input
     * Sets the username of the new linked account to be added
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    handleInputChange(event) {
        const {value, name} = event.target;
        this.setState({
            [name]: value.trim(),
        });
    }

    /**
     * Helper method to get identifying key for an account
     *
     * @param {*} account
     * @return {*} 
     * @memberof LinkedAccounts
     */
    getAccountKey(account) {
        return `${account.username + "-" + account.linkedUsername}`;
    }

    /**
     * Helper method to render the currently linked accounts as table rows
     *
     * @return {*}
     * @memberof LinkedAccounts
     */
    getLinkedAccounts() {
        const linkedAccounts = Object.values(this.state.linkedAccounts).flat();
        linkedAccounts.sort((a,b) => {
            if (a.username === b.username) {
                return a.linkedUsername.localeCompare(b.linkedUsername);
            }
            return a.username.localeCompare(b.username);
        });
        const newdata = linkedAccounts.map( (account) => {
            if(this.state.updateLinkedAccount === this.getAccountKey(account)) {
                // updating row
                return (
                    <tr key={this.getAccountKey(account)}>
                        <td><input type="text" maxLength="256" name="updateLinkedAccountLocalValue" value={this.state.updateLinkedAccountLocalValue} onChange={this.handleInputChange.bind(this)} required disabled></input></td>
                        <td><input type="text" maxLength="256" name="updateLinkedAccountLinkedValue" value={this.state.updateLinkedAccountLinkedValue} onChange={this.handleInputChange.bind(this)} required></input></td>
                        <td><input type="text" maxLength="256" name="updateLinkedAccountDescription" value={this.state.updateLinkedAccountDescription} onChange={this.handleInputChange.bind(this)}></input></td>
                        <td className="empty">
                            <div>
                            <input id={this.getAccountKey(account)} className="submit" type="submit" value=""></input>
                            <label htmlFor={this.getAccountKey(account)} className="checkmark confirm-icon"></label>
                            </div>
                            <div className="cancel confirm-icon icon" onClick={this.cancelEditAccount.bind(this)}></div>
                        </td>
                    </tr>
                );
            } else {
                return (
                    <tr key={this.getAccountKey(account)}>
                        <td>{account.username}</td>
                        <td>{account.linkedUsername}</td>
                        <td>{account.description}</td>
                        <td className="empty">
                            <img src={editIcon} className="edit-icon icon" alt="logo" updateaccount={this.getAccountKey(account)} username={account.username} linkedusername={account.linkedUsername} description={account.description} onClick={this.setEditAccount.bind(this)}/>
                            <img src={trashIcon} className="trash-icon icon" alt="logo" removeaccount={this.getAccountKey(account)} username={account.username} linkedusername={account.linkedUsername} onClick={this.setRemoveAccount.bind(this)}/>
                        </td>
                    </tr>
                );
            }
        });

        if (this.state.newLinkedAccount) {
            newdata.push(
                <tr key="new-account-input">
                    <td><input type="text" maxLength="256" name="newLinkedAccountLocalValue" value={this.state.newLinkedAccountLocalValue} onChange={this.handleInputChange.bind(this)} required></input></td>
                    <td><input type="text" maxLength="256" name="newLinkedAccountLinkedValue" value={this.state.newLinkedAccountLinkedValue} onChange={this.handleInputChange.bind(this)} required></input></td>
                    <td><input type="text" maxLength="256" name="newLinkedAccountDescription" value={this.state.newLinkedAccountDescription} onChange={this.handleInputChange.bind(this)}></input></td>
                    <td className="empty">
                        <div>
                            <input id="new-row" className="submit" type="submit" value=""></input>
                            <label htmlFor="new-row" className="checkmark confirm-icon icon"></label>
                        </div>
                        <div className="cancel confirm-icon icon" onClick={this.cancelAddAccount.bind(this)}></div>
                    </td>
                </tr>
            );
        }

        return newdata;
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof LinkedAccounts
     */
    render() {
        return (
            <div className="settings-content">
                <div className="settings-linked-accounts section">
                    <button className="add-new button" onClick={this.addNewLinkedAccount.bind(this)}>+</button>
                    <div className="settings-header">Linked Accounts</div>
                    <div className="settings-message">All incoming files from linked accounts below will automatically be received by this account.</div>
                    <form className="form" onSubmit={this.updateLinkedAccounts.bind(this)}>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>Local Username</th>
                            <th>Linked Username</th>
                            <th>Description</th>
                            <th className="empty"></th>
                        </tr>
                        </thead>
                        <tbody ref={this.tableBody}>
                        { this.getLinkedAccounts() }
                        </tbody>
                    </table>
                    </form>
                    
                    {
                    this.state.error ?
                    (<div className="message error-message">Request failed - {this.state.error}</div>) : null
                    }
                    {
                    this.state.success ?
                    (<div className="message success-message" ref={this.successMessage}>Request succeeded</div>) : null
                    }
                    {
                    this.state.pending ?
                    (<div className="message info-message"><div className="message spinner spinner-small"></div>Request processing</div>) : null
                    }

                    {
                    this.state.removeLinkedAccount ?
                    (<div className="modal">
                        <div className="overlay"></div>
                        <div className="modal-dialog">
                            <div>Are you sure you want to remove linkage between {this.state.removeLinkedAccountLocalValue} and {this.state.removeLinkedAccountLinkedValue}?</div>
                            <button className="button" onClick={this.updateLinkedAccounts.bind(this)}>CONFIRM</button>
                            <div className="cancel-link" onClick={this.setRemoveAccount.bind(this)}>Cancel</div>
                        </div>
                    </div>) : null
                    }
                </div>
            </div>
        );
    }

}

export default LinkedAccounts;