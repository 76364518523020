import React from 'react';
import ReactDropzone from 'react-dropzone';
import { NavLink } from 'react-router-dom';
import axios, { CancelToken } from 'axios';

import './SendFiles.scss';

import filesIcon from '../../constants/images/files.svg';
import AuthHelperMethods from '../Login/AuthHelperMethods';
import ProgressBar, {setPercentage} from '../ProgressBar/ProgressBar';
import { bytesToSize } from '../../utils/common';
import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';


class SendNewFiles extends React.Component {

    /**
     * Creates an instance of SendNewFiles.
     * @param {*} props
     * @memberof SendNewFiles
     */
    constructor(props) {
        super(props);

        this.state = {
            filesUploading: {}, // TODO lose this state once navigated away
            filesToSendIds: [],
            inProgressFiles: [],
            currentEndpoint: false,
        };

        this.selectedRecipient = React.createRef();
        this.Auth = new AuthHelperMethods();
        this.cancelToken = CancelToken.source();
        this._mounted = false;
    }

    /**
     * React method triggered when component is mounted to DOM
     * Initiates timer to poll for transfer files request
     *
     * @memberof SendNewFiles
     */
    componentDidMount() {
        this.getFilesRequest();
        this.timer = setInterval(() => this.getFilesRequest(), 2000);
        if (this.props.endpoints.length === 1) {
            let currentEndpoint = this.props.endpoints[0];
            this.setState({
                currentEndpoint: currentEndpoint.endpointId, 
            });
        }
        
        this._mounted = true;
    }

    /**
     * React method triggered when component is unmounted from DOM
     * Cancels timer to poll for transfer files request
     *
     * @memberof SendNewFiles
     */
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        this.cancelToken.cancel("Request cancelled");
        this._mounted = false;
    }

    /**
     * Event handler for adding new files to be uploaded
     *
     * @param {*} acceptedFiles
     * @param {*} rejectedFiles
     * @param {*} event
     * @memberof SendNewFiles
     */
    onPreviewDrop(acceptedFiles, rejectedFiles, event) {
        let requestPath = `${REACT_APP_PROXY_SERVER}/files/transfer`;
        if (this.state.currentEndpoint) {
            requestPath = requestPath.concat(`/${this.state.currentEndpoint}`);
        }

        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${accessString}`
            }
        };
        for (let file of rejectedFiles) {
            let parsedFile = {
                "transferId": `${file.name}-${file.lastModified}`,
                "endpointId": this.state.currentEndpoint,
                "filename": file.name,
                "filesize": file.size,
                "username": "john.doe@example.com",
                "started": new Date(file.lastModified).toLocaleString(),
                "state": "Failure - Could not upload",
                "stages": []
            };
            let uploading = Object.assign({}, this.state.filesUploading);
            uploading[parsedFile.transferId] = parsedFile;
            this.setState({
                filesUploading: uploading
            });
        }

        for (let file of acceptedFiles) {

            const reader = new FileReader();

            let parsedFile = {
                "transferId": `${file.name}-${file.lastModified}`,
                "endpointId": this.state.currentEndpoint,
                "filename": file.name,
                "filesize": file.size,
                "username": "john.doe@example.com",
                "started": new Date(file.lastModified).toLocaleString(),
                "state": "Uploading",
                "stages": []
            };
            let uploading = Object.assign({}, this.state.filesUploading);
            uploading[parsedFile.transferId] = parsedFile;
            this.setState({
                filesUploading: uploading
            });

            reader.onabort = () => {
                console.log("file reading was aborted");
                let uploading = Object.assign({}, this.state.filesUploading);
                uploading[parsedFile.transferId].state = "Failure - Could not upload";
                this.setState({
                    filesUploading: uploading
                });
            }
            reader.onerror = () => {
                console.log("file reading has failed");
                let uploading = Object.assign({}, this.state.filesUploading);
                uploading[parsedFile.transferId].state = "Failure - Could not upload";
                this.setState({
                    filesUploading: uploading
                });
            }
            reader.onload = () => {
                let body = new FormData();
                body.append("file", file, file.name);

                axios.post(requestPath, body, options)
                        .then(response => {
                            if (!this._mounted) return;

                            const fileId = response.data["transferId"];
                            let uploading = Object.assign({}, this.state.filesUploading);
                            delete uploading[parsedFile.transferId];
                            let filesToSendIds = this.state.filesToSendIds.slice();
                            this.setState({
                                filesToSendIds: filesToSendIds.concat(fileId),
                                filesUploading: uploading,
                            });
                        })
                        .catch(err => {
                            console.log(err);
                            if (!this._mounted) return;

                            let uploading = Object.assign({}, this.state.filesUploading);
                            uploading[parsedFile.transferId].state = "Failure - Could not upload";
                            this.setState({
                                filesUploading: uploading
                            });
                        });
            };
            reader.readAsArrayBuffer(file);
        }
    }

    /**
     * Helper method to get files that have been uploaded on this screen
     *
     * @param {Array} files
     * @memberof SendNewFiles
     */
    parseFiles(files) {
        let inProgress = [];
        for (let file of files) {
            if(this.state.filesToSendIds.indexOf(file["transferId"]) !== -1) {
                inProgress.push(file);
            }
        }

        this.setState({
            inProgressFiles: inProgress,
        });
    }

    /**
     * Request for all files that have been sent
     *
     * @memberof SendFiles
     */
    getFilesRequest() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/transfer`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };

        axios.get(requestPath, {...options, cancelToken: this.cancelToken.token,})
                .then(response => {
                    const files = response.data;
                    this.parseFiles(files);
                })
                .catch(err => {
                    console.log(err);
                });
    }

    /**
     * Helper method to handle dropdown input for endpoint
     *
     * @param {*} event
     * @memberof SendNewFiles
     */
    handleInputChange(event) {
        let value = event.target.value;
        let currentEndpoint = this.props.endpoints.filter((e) => {
            return e.endpointId === value;
        });
        this.setState({
            currentEndpoint: currentEndpoint.length > 0 ? currentEndpoint[0].endpointId : "", 
        });
    }

    /**
     * Helper method to build dropdown menu options
     *
     * @return {*} 
     * @memberof SendNewFiles
     */
    parseEndpoints() {
        const endpoints = this.props.endpoints || [];

        const newdata = endpoints.map(endpoint => {
            let endpointInfo = endpoint.endpoint;
            return (
                <option key={endpoint.endpointId} value={endpoint.endpointId}>{endpointInfo.name}</option>
            );
        });

        return newdata;
    }

    /**
     * Helper method to render files that have been uploaded as table rows
     *
     * @return {*}
     * @memberof SendNewFiles
     */
    getSentFilesStatus() {
        const inProgressFiles = this.state.inProgressFiles.slice();
        const pendingFiles = Object.values(this.state.filesUploading);
        const endpoints = this.props.endpoints || [];
        inProgressFiles.sort((a,b) => {
            return a.started.localeCompare(b.started);
        });
        pendingFiles.sort((a,b) => {
            return a.started.localeCompare(b.started);
        });

        let newdata = pendingFiles.map( (file) => {
            let endpointInfo = endpoints.find((endpoint) => {
                return endpoint.endpointId === file.endpointId;
            }) || {"endpoint": {"name": "Unknown"}};
            return (
                <tr key={file.filename + " " + file.started}>
                    <td>{file.filename}</td>
                    <td>{endpointInfo.endpoint.name}</td>
                    <td>{file.started}</td>
                    <td className="status">
                        <ProgressBar stages={file.stages} filestate={file.state} {...setPercentage(file.stages, file.finished)}></ProgressBar>
                        <span className="filesize">{bytesToSize(file.filesize)}</span>
                    </td>
                </tr>
            );
        });

        newdata = newdata.concat(inProgressFiles.map( (file) => {
            let endpointInfo = endpoints.find((endpoint) => {
                return endpoint.endpointId === file.endpointId;
            }) || {"endpoint": {"name": "Unknown"}};
            return (
                <tr key={file.filename + " " + file.started}>
                    <td>{file.filename}</td>
                    <td>{endpointInfo.endpoint.name}</td>
                    <td>{file.started}</td>
                    <td className="status">
                        <ProgressBar stages={file.stages} filestate={file.state} {...setPercentage(file.stages, file.finished)}></ProgressBar>
                        <span className="filesize">{bytesToSize(file.filesize)}</span>
                    </td>
                </tr>
            );
        }));

        return newdata;
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof SendNewFiles
     */
    render() {
        return (
            <div className="send-content">
                <div className="section">
                    <div className="recipients">
                        <span>Choose recipient: </span>
                        <select className="recipient-list" onChange={this.handleInputChange.bind(this)} value={this.state.currentEndpoint}>
                            <option className="disabled" value={false} disabled>Please choose an endpoint...</option>
                            { this.parseEndpoints() }
                        </select>
                        {
                            !this.state.currentEndpoint ?
                            (<div className="message error-message">You must choose an endpoint prior to sending files</div>) : null
                        }
                        
                    </div>
                    <div className="section-content">
                        <ReactDropzone onDrop={this.onPreviewDrop.bind(this)}>
                            {({getRootProps, getInputProps, isDragActive}) => (
                                <div {...getRootProps({className: `dropzone ${!this.state.currentEndpoint ? "disabled" : ""}`})}>
                                    <input {...getInputProps()} />
                                    <div className="messages">
                                        <img src={filesIcon} className="files-icon" alt="logo"/>
                                        <div className="drag-message">{isDragActive ? "Yay files!" : "Drag files here"}</div>
                                        <div className="click-message">or click anywhere to browse files</div>
                                    </div>
                                </div>
                            )}
                        </ReactDropzone>
                    </div>
                </div>

                <div className="sending-files section">
                    <span>In Progress</span>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Recipient</th>
                            <th>Transfer Initiated</th>
                            <th className="status">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.getSentFilesStatus()
                        }
                        </tbody>
                    </table>
                </div>

                <button className="close-link button">
                    <NavLink to="/send">CLOSE</NavLink>
                </button>
            </div>
        );
    }
}

export default SendNewFiles;