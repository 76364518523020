import React from 'react';
import moment from 'moment';
import axios, { CancelToken } from 'axios';

import AuthHelperMethods from '../Login/AuthHelperMethods';
import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

class Dashboard extends React.Component {

    /**
     * Creates an instance of Dashboard.
     * @param {*} props
     * @memberof Dashboard
     */
    constructor(props) {
        super(props);
        this.state = {
            transferredFiles: [],
        };

        this.Auth = new AuthHelperMethods();

        this.cancelToken = CancelToken.source();
    }

    /**
     * React method triggered when component is mounted to DOM
     * Initiates timer to poll for received files request
     *
     * @memberof Dashboard
     */
    componentDidMount() {
        this.getFilesRequest();
        this.timer = setInterval(() => this.getFilesRequest(), 15000);
    }

    /**
     * React method triggered when component is unmounted from DOM
     * Cancels timer to poll for received files request
     *
     * @memberof Dashboard
     */
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        this.cancelToken.cancel("Request cancelled");
    }


    /**
     * Parses sent and received files and combines them into one list
     *
     * @param {*} received A list of received files
     * @param {*} sent A list of sent files
     * @memberof Dashboard
     */
    parseFiles(received, sent) {
        let files = [];
        let message;
        for (let r of received) {
            if (r.state === "Failure") {
                message = `There was an error receiving ${r.filename}`;
            }
            else if (!r.finished) {
                message = `${r.filename} is being received`;
            } else {
                message = `You've received a file ${r.filename}`;
            }
            files.push(Object.assign({}, r, {
                direction: "receive",
                message: message,
            }));
        }
        for (let s of sent) {
            if (s.state === "Failure") {
                message = `There was an error sending ${s.filename}`;
            }
            if (!s.finished) {
                message = `${s.filename} is being sent`;
            } else {
                message = `Your file ${s.filename} was sent`;
            }
            files.push(Object.assign({}, s, {
                direction: "send",
                message: message,
            }));
        }

        this.setState({
            transferredFiles: files,
        });
    }

    /**
     * Request for all files that have been received
     *
     * @memberof Dashboard
     */
    getFilesRequest() {
        // TODO only do what permissions allow
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };


        const receivedRequestPath = `${REACT_APP_PROXY_SERVER}/files/received`;
        const receivedRequest = axios.get(receivedRequestPath, {...options, cancelToken: this.cancelToken.token,});

        const sentRequestPath = `${REACT_APP_PROXY_SERVER}/files/transfer`
        const sentRequest = axios.get(sentRequestPath, {...options, cancelToken: this.cancelToken.token,});

        axios.all([receivedRequest, sentRequest])
                .then(axios.spread((...responses) => {
                    const receiveResponse = responses[0].data;
                    const sentResponse = responses[1].data;

                    this.parseFiles(receiveResponse, sentResponse);
                }))
                .catch(errors => {
                    console.log(errors);
                });
    }

    /**
     * Return all recent file transfers for dashboard
     *
     * @return {*}
     * @memberof Dashboard
     */
    getRecentActivity() {
        let transferredFiles = this.state.transferredFiles.slice();

        transferredFiles= transferredFiles.sort((a,b) => {
            let aTime = a.finished ? a.finished : a.started;
            let bTime = b.finished ? b.finished : b.started;
            return bTime.localeCompare(aTime);
        });
        
        const newdata = transferredFiles.map( (file) => {
            let transferTime = file.finished ? file.finished : file.started;

            return (
                <tr key={file.fileName + " " + file.started}>
                    <td>{file.message}</td>
                    <td className="activity-time" title={transferTime}>{moment(transferTime).fromNow()}</td>
                </tr>
            );
        });

        return newdata;
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof Dashboard
     */
    render() {
        return (
            <div className="section">
                <div className="content-header">{`Hi${this.props.confirm ? ", " + this.props.confirm.firstName : " there"}!`}</div>

                <span>Recent Activity</span>
                <table className="section-content">
                    <tbody>
                    { this.getRecentActivity() }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Dashboard;