import React from 'react';
import axios from 'axios';

import './RecipientHosts.scss';
import trashIcon from '../../constants/images/trash.svg';
import editIcon from '../../constants/images/edit.svg';
import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

import AuthHelperMethods from '../Login/AuthHelperMethods';

class RecipientHosts extends React.Component {

    /**
     * Creates an instance of RecipientHosts.
     * @param {*} props
     * @memberof RecipientHosts
     */
    constructor(props) {
        super(props);

        this.state = {
            endpoints: [],
            newEndpoint: false,
            newEndpointScroll: false,
            newEndpointHostname: "",
            newEndpointPort: undefined,
            newEndpointDescription: "",
            updateEndpoint: false,
            updateEndpointHostname: "",
            updateEndpointPort: undefined,
            updateEndpointDescription: "",
            removeEndpoint: false,
            removeEndpointName: "",
            pending: false,
            error: false,
            success: false,
        };

        this.Auth = new AuthHelperMethods();

        this.tableBody = React.createRef();
        this.successMessage = React.createRef();
        this.successTimeout = null;
    }

    /**
     * React method triggered when component is mounted to DOM
     * Sends initial request to get configured endpoints for user
     *
     * @memberof RecipientHosts
     */
    componentDidMount() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/endpoints`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };

        axios.get(requestPath, options)
                .then(response => {
                    const endpoints = response.data;
                    this.setState({
                        endpoints: endpoints,
                    });
                })
                .catch(err => {
                    console.log(err);
                });
    }

    /**
     * Cancels any timeout for the success message fade
     *
     * @memberof RecipientHosts
     */
    componentWillUnmount() {
        if (this.successTimeout) {
            clearTimeout(this.successTimeout);
        }
    }

    /**
     * Scrolls to the bottom of the list to maintain visibility of new account row and fades success message
     *
     * @memberof RecipientHosts
     */
    componentDidUpdate() {
        if(this.state.newEndpointScroll) {
            this.tableBody.current.scrollTo(0, this.tableBody.current.scrollHeight);
            this.setState({
                newEndpointScroll: false,
            });
        }

        if(this.state.success) {
            this.successTimeout = setTimeout(() => {
                this.successMessage.current.style.opacity = 0; 
                this.successTimeout = null;
            }, 5000);
        }
    }

    /**
     * Helper method to trigger input box to enter new recipient details
     *
     * @param {*} event A click event
     * @memberof RecipientHosts
     */
    addNewEndpoint(event) {
        this.setState({
            newEndpoint: "new-row",
            newEndpointScroll: true,
            newEndpointHostname: "",
            newEndpointPort: undefined,
            newLinkedAccountDescription: "",
        });
    }

    /**
     * Request for new transfer endpoint to be registered
     *
     * @memberof RecipientHosts
     */
    addNewEndpointRequest() {
        let existingEndpointIndex = this.state.endpoints.findIndex((e) => {
            return e.endpoint.hostname === this.state.newEndpointHostname && e.endpoint.port === this.state.newEndpointPort;
        });
        if (existingEndpointIndex !== -1) {
            // already exists!
            this.setState({
                error: "Endpoint already exists!",
                success: false,
            });
        } else {
            
            let currentEndpoint = {
                name: `${this.state.newEndpointHostname}:${this.state.newEndpointPort}`,
                hostname: this.state.newEndpointHostname,
                port: parseInt(this.state.newEndpointPort || 0),
                description: this.state.newEndpointDescription,
            };

            this.setState({
                pending: true,
                success: false,
                error: false,
            });
            const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/endpoints`;
            const accessString = this.Auth.getToken();
            const options = {
                params: {},
                headers: {
                    "Authorization": `Bearer ${accessString}`,
                },
            };

            axios.post(requestPath, currentEndpoint, options)
                    .then(response => {
                        let endpointId = response.data.endpointId;
                        this.setState({
                            pending: false,
                            newEndpoint: false,
                            newEndpointScroll: false,
                            newEndpointHostname: "",
                            newEndpointPort: "",
                            newEndpointDescription: "",
                            endpoints: this.state.endpoints.concat({
                                endpointId: endpointId,
                                endpoint: currentEndpoint,
                            }),
                            error: false,
                            success: true,
                        });
                    })
                    .catch(err => {
                        const errMsg = `Cannot add endpoint ${currentEndpoint.name}`;
                        this.setState({
                            error: errMsg,
                            success: false,
                            pending: false,
                        });
                        console.log(err);
                    });
        }
    }

    /**
     * Request for transfer endpoint to be removed
     *
     * @memberof RecipientHosts
     */
    deleteEndpointRequest() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/endpoints/${this.state.removeEndpoint}`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                "Authorization": `Bearer ${accessString}`,
            },
        };

        this.setState({
            pending: true,
            removeEndpoint: false,
            success: false,
            error: false,
        });

        axios.delete(requestPath, options)
                .then(response => {
                    let endpoints = this.state.endpoints.slice();
                    let endpoint = this.state.endpoints.findIndex((e) => {
                        return e.endpointId === this.state.removeEndpoint;
                    })
                    endpoints.splice(endpoint, 1);
                    this.setState({
                        pending: false,
                        removeEndpoint: false,
                        removeEndpointName: "",
                        error: false,
                        success: true,
                        endpoints: endpoints,
                    });
                })
                .catch(err => {
                    const errMsg = `Cannot remove endpoint ${this.state.removeEndpointName}`;
                    this.setState({
                        error: errMsg,
                        success: false,
                        pending: false,
                    });
                    console.log(err);
                });

    }

    /**
     * Request to update existing transfer endpoint
     *
     * @memberof RecipientHosts
     */
    editEndpointRequest() {
        let updatedEndpoint = {
            name: `${this.state.updateEndpointHostname}:${this.state.updateEndpointPort}`,
            hostname: this.state.updateEndpointHostname,
            port: parseInt(this.state.updateEndpointPort || 0),
            description: this.state.updateEndpointDescription,
        };

        this.setState({
            pending: true,
            success: false,
            error: false,
        });
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/admin/endpoints/${this.state.updateEndpoint}`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                "Authorization": `Bearer ${accessString}`,
            },
        };

        axios.post(requestPath, updatedEndpoint, options)
                .then(response => {
                    let updatedEndpoints = this.state.endpoints.filter(e => {
                        return e.endpointId !== this.state.updateEndpoint;
                    });
                    updatedEndpoints = updatedEndpoints.concat({
                        endpointId: this.state.updateEndpoint,
                        endpoint: updatedEndpoint,
                    });
                    this.setState({
                        pending: false,
                        updateEndpoint: false,
                        updateEndpointHostname: "",
                        updateEndpointPort: "",
                        updateEndpointDescription: "",
                        endpoints: updatedEndpoints,
                        error: false,
                        success: true,
                    });
                })
                .catch(err => {
                    const errMsg = `Cannot edit endpoint ${updatedEndpoint.name}`;
                    this.setState({
                        error: errMsg,
                        success: false,
                        pending: false,
                    });
                    console.log(err);
                });

    }

    /**
     * Event handler for the save button
     * Sends request to update linked accounts for current user based on added/removed account actions by user
     *
     * @memberof RecipientHosts
     */
    updateEndpoints(event) {
        event.preventDefault();
        const submitter = event.nativeEvent.submitter;
        if (this.state.newEndpoint && submitter.id === this.state.newEndpoint) {
            this.addNewEndpointRequest();
        }
        else if (this.state.updateEndpoint && submitter.id === this.state.updateEndpoint){
            this.editEndpointRequest();
        }
        else if (this.state.removeEndpoint) {
            this.deleteEndpointRequest();
        }
        this.props.getNewEndpoints();
    }

    /**
     * Helper method to trigger input boxes to update endpoint details
     *
     * @param {*} event
     * @memberof RecipientHosts
     */
    setEditEndpoint(event) {
        let endpoint = event.target.getAttribute("updateendpoint");
        let hostname = event.target.getAttribute("hostname");
        let port = event.target.getAttribute("port");
        let description = event.target.getAttribute("description");

        this.setState({
            updateEndpoint: endpoint,
            updateEndpointHostname: hostname || "",
            updateEndpointPort: port || 0,
            updateEndpointDescription: description || "",
        });
    }

    /**
     * Helper method to remove edit input boxes
     *
     * @param {*} event
     * @memberof RecipientHosts
     */
    cancelEditEndpoint(event) {
        this.setState({
            updateEndpoint: false,
            updateEndpointHostname: "",
            updateEndpointPort: "",
            updateEndpointDescription: "",
        });
    }

    /**
     * Helper method to remove add input boxes
     *
     * @param {*} event
     * @memberof RecipientHosts
     */
    cancelAddEndpoint(event) {
        this.setState({
            newEndpoint: false,
            newEndpointScroll: false,
            newEndpointHostname: "",
            newEndpointPort: "",
            newEndpointDescription: "",
        });
    }

    /**
     * Helper method to trigger modal to confirm delete request
     *
     * @param {*} event
     * @memberof RecipientHosts
     */
    setRemoveEndpoint(event){
        if (this.state.removeEndpoint) {
            this.setState({
                removeEndpoint: false,
                removeEndpointName: "",
            });    
        } else {
            const endpointId = event.target.getAttribute("removeendpoint");
            const name = event.target.getAttribute("name");
            this.setState({
                removeEndpoint: endpointId,
                removeEndpointName: name,
            });
        }
    }

    /**
     * Event handler for new input
     * Sets the username of the new linked account to be added
     *
     * @param {*} event
     * @memberof LinkedAccounts
     */
    handleInputChange(event) {
        const {value, name} = event.target;
        this.setState({
            [name]: value.trim(),
        });
    }

    /**
     * Helper method to parse recipient hosts as table rows
     *
     * @return {*}
     * @memberof RecipientHosts
     */
    getRecipientHosts() {
        const recipientHosts = this.state.endpoints.slice();
        recipientHosts.sort((a,b) => {
            if (a.endpoint.hostname === b.endpoint.hostname) {
                return a.endpoint.port - b.endpoint.port;
            }
            return a.endpoint.hostname.localeCompare(b.endpoint.hostname);
        });
        const newdata = recipientHosts.map( (e) => {
            if(this.state.updateEndpoint === e.endpointId) {
                // updating row
                return (
                    <tr key={e.endpointId}>
                        <td><input type="text" maxLength="256" name="updateEndpointHostname" value={this.state.updateEndpointHostname} onChange={this.handleInputChange.bind(this)} required></input></td>
                        <td><input type="number" min="0" max="65535" name="updateEndpointPort" value={this.state.updateEndpointPort} onChange={this.handleInputChange.bind(this)} required></input></td>
                        <td><input type="text" maxLength="256" name="updateEndpointDescription" value={this.state.updateEndpointDescription} onChange={this.handleInputChange.bind(this)}></input></td>
                        <td className="empty">
                            <div>
                            <input id={e.endpointId} className="submit" type="submit" value=""></input>
                            <label htmlFor={e.endpointId} className="checkmark confirm-icon"></label>
                            </div>
                            <div className="cancel confirm-icon icon" onClick={this.cancelEditEndpoint.bind(this)}></div>
                        </td>
                    </tr>
                );
            } else {
                return (
                    <tr key={e.endpointId}>
                        <td>{e.endpoint.hostname}</td>
                        <td>{e.endpoint.port}</td>
                        <td>{e.endpoint.description}</td>
                        <td className="empty">
                            <img src={editIcon} className="edit-icon icon" alt="logo" updateendpoint={e.endpointId} hostname={e.endpoint.hostname} port={e.endpoint.port} description={e.endpoint.description} onClick={this.setEditEndpoint.bind(this)}/>
                            <img src={trashIcon} className="trash-icon icon" alt="logo" removeendpoint={e.endpointId} name={`${e.endpoint.hostname}:${e.endpoint.port}`} onClick={this.setRemoveEndpoint.bind(this)}/>
                        </td>
                    </tr>
                );
            }
            
        });

        if (this.state.newEndpoint) {
            newdata.push(
                <tr key="new-account-input">
                    <td><input type="text" maxLength="256" name="newEndpointHostname" value={this.state.newEndpointHostname} onChange={this.handleInputChange.bind(this)} required></input></td>
                    <td><input type="number" min="0" max="65535" name="newEndpointPort" value={this.state.newEndpointPort} onChange={this.handleInputChange.bind(this)} required></input></td>
                    <td><input type="text" maxLength="256" name="newEndpointDescription" value={this.state.newEndpointDescription} onChange={this.handleInputChange.bind(this)}></input></td>
                    <td className="empty">
                        <div>
                            <input id="new-row" className="submit" type="submit" value=""></input>
                            <label htmlFor="new-row" className="checkmark confirm-icon icon"></label>
                        </div>
                        <div className="cancel confirm-icon icon" onClick={this.cancelAddEndpoint.bind(this)}></div>
                    </td>
                </tr>
            );
        }

        return newdata;
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof RecipientHosts
     */
    render() {
        return (
            <div className="settings-content">
                <div className="settings-recipient-hosts section">
                    <button className="add-new button" onClick={this.addNewEndpoint.bind(this)}>+</button>
                    <div className="settings-header">Recipient Hosts</div>
                    <div className="settings-message">These configured hosts will be available options for sending files.</div>

                    <form className="form" onSubmit={this.updateEndpoints.bind(this)}>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>Hostname</th>
                            <th>Port</th>
                            <th>Description</th>
                            <th className="empty"></th>
                        </tr>
                        </thead>
                        <tbody ref={this.tableBody}>
                        { this.getRecipientHosts() }
                        </tbody>
                    </table>
                    </form>

                    {
                    this.state.error ?
                    (<div className="message error-message">Request failed - {this.state.error}</div>) : null
                    }
                    {
                    this.state.success ?
                    (<div className="message success-message" ref={this.successMessage}>Request succeeded</div>) : null
                    }
                    {
                    this.state.pending ?
                    (<div className="message info-message"><div className="message spinner spinner-small"></div>Request processing</div>) : null
                    }

                    {
                    this.state.removeEndpoint ?
                    (<div className="modal">
                        <div className="overlay"></div>
                        <div className="modal-dialog">
                            <div>Are you sure you want to remove endpoint {this.state.removeEndpointName}?</div>
                            <button className="button" onClick={this.updateEndpoints.bind(this)}>CONFIRM</button>
                            <div className="cancel-link" onClick={this.setRemoveEndpoint.bind(this)}>Cancel</div>
                        </div>
                    </div>) : null
                    }
                </div>
            </div>
        );
    }
}

export default RecipientHosts;