import React from 'react';

import './GeneralSettings.scss';

class GeneralSettings extends React.Component {

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof GeneralSettings
     */
    render() {
        if (!this.props.confirm) {
            return (
                <div className="settings-content">
                    <div className="settings-header">About</div>
                    Error retrieving settings information
                </div>
            );
        } else {
            return (
                <div className="settings-content">
                    <div className="settings-header">About</div>

                    <div className="settings-name setting">
                        <div className="label">Full name</div>
                        <input disabled value={`${this.props.confirm.firstName} ${this.props.confirm.lastName}`}></input>
                    </div>

                    <div className="settings-username setting">
                        <div className="label">Username</div>
                        <input disabled value={this.props.confirm.username}></input>
                    </div>

                    <div className="settings-email setting">
                        <div className="label">Email address</div>
                        <input disabled value={this.props.confirm.email}></input>
                    </div>
                </div>
            );
        }
    }
}

export default GeneralSettings;