import React from 'react';
import { NavLink } from 'react-router-dom';
import axios, { CancelToken } from 'axios';

import './SendFiles.scss';

import AuthHelperMethods from '../Login/AuthHelperMethods';
import ProgressBar, {setPercentage} from '../ProgressBar/ProgressBar';
import { bytesToSize } from '../../utils/common';
import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

class SendFiles extends React.Component {

    /**
     * Creates an instance of SendFiles.
     * @param {*} props
     * @memberof SendFiles
     */
    constructor(props) {
        super(props);

        this.state = {
            inProgressFiles: [],
            sentFiles: [],
        };

        this.Auth = new AuthHelperMethods();

        this.cancelToken = CancelToken.source();
    }

    /**
     * React method triggered when component is mounted to DOM
     * Initiates timer to poll for transfer files request
     *
     * @memberof SendFiles
     */
    componentDidMount() {
        this.getFilesRequest();
        this.timer = setInterval(() => this.getFilesRequest(), 2000);
    }

    /**
     * React method triggered when component is unmounted from DOM
     * Cancels timer to poll for transfer files request
     *
     * @memberof SendFiles
     */
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        this.cancelToken.cancel("Request cancelled");
    }

    /**
     * Helper method to split sent response into files that are in progress vs already sent
     *
     * @param {Array} files
     * @memberof SendFiles
     */
    parseFiles(files) {
        let inProgress = [];
        let sent = [];
        for (let file of files) {
            if(file.finished) {
                sent.push(file);
            } else {
                inProgress.push(file);
            }
        }

        this.setState({
            inProgressFiles: inProgress,
            sentFiles: sent,
        });
    }

    /**
     * Request for all files that have been sent
     *
     * @memberof SendFiles
     */
    getFilesRequest() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/transfer`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };

        axios.get(requestPath, {...options, cancelToken: this.cancelToken.token,})
                .then(response => {
                    const files = response.data;
                    this.parseFiles(files);
                })
                .catch(err => {
                    console.log(err);
                });
    }

    /**
     * Helper method to render files that are being sent as table rows
     *
     * @return {*}
     * @memberof SendFiles
     */
    getInProgressFiles() {
        const inProgressFiles = this.state.inProgressFiles.slice();
        const endpoints = this.props.endpoints || [];
        inProgressFiles.sort((a,b) => {
            return a.started.localeCompare(b.started);
        });

        const newdata = inProgressFiles.map( (file) => {
            let endpointInfo = endpoints.find((endpoint) => {
                return endpoint.endpointId === file.endpointId;
            }) || {"endpoint": {"name": "Unknown"}};
            return (
                <tr key={file.filename + " " + file.started}>
                    <td>{file.filename}</td>
                    <td>{endpointInfo.endpoint.name}</td>
                    <td>{file.started}</td>
                    <td className="status">
                        <ProgressBar stages={file.stages} filestate={file.state} {...setPercentage(file.stages)}></ProgressBar>
                        <span className="filesize">{bytesToSize(file.filesize)}</span>
                    </td>
                </tr>
            );
        });

        return newdata;
    }

    /**
     * Helper method to render files that are have been sent as table rows
     *
     * @return {*}
     * @memberof SendFiles
     */
    getSentFiles() {
        const sentFiles = this.state.sentFiles.slice();
        const endpoints = this.props.endpoints || [];
        sentFiles.sort((a,b) => {
            return b.finished.localeCompare(a.finished);
        });

        const newdata = sentFiles.map( (file) => {
            let endpointInfo = endpoints.find((endpoint) => {
                return endpoint.endpointId === file.endpointId;
            }) || {"endpoint": {"name": "Unknown"}};
            return (
                <tr key={`${file.filename} ${file.started}`}>
                    <td>{file.filename}</td>
                    <td>{endpointInfo.endpoint.name}</td>
                    <td>{file.started}</td>
                    <td>{file.finished}</td>
                    <td>{bytesToSize(file.filesize)}</td>
                </tr>
            );
        });

        return newdata;
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof SendFiles
     */
    render() {
        return (
            <div className="send-content">
                <NavLink to="/send-new">
                    <button className="send-new-files button">SEND NEW FILES</button>
                </NavLink>
                <div className="in-progress section">
                    <span>In Progress</span>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Recipient</th>
                            <th>Transfer Initiated</th>
                            <th className="status">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.getInProgressFiles() }
                        </tbody>
                    </table>
                </div>
                <div className="sent-files section">
                    <span>Sent Files</span>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Recipient</th>
                            <th>Transfer Initiated</th>
                            <th>Transfer Completed</th>
                            <th>File Size</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.getSentFiles() }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default SendFiles;