import React from 'react';

import './ProgressBar.scss';

const setPercentage = function(stages, finished) {
    let numStages = stages.length;
    let inProgressStage = stages.find((s) => {
        return s.started && !s.finished;
    }) || {};

    return {
        currentStage: inProgressStage.name,
        currentStageIndex: !inProgressStage.name ? numStages : stages.indexOf(inProgressStage) + 1,
        currentStageState: inProgressStage.state,
        percentage: finished ? 100 : (inProgressStage.progress * 100) || 0,
        totalStages: numStages,
    };
};

class ProgressBar extends React.Component {
    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof ProgressBar
     */
    render() {
        return (
            <div className="progress-bar">
                <div className={`bar outer-container ${this.props.percentage === 0 && this.props.currentStageState !== "Queued" && this.props.filestate.indexOf("Failure") === -1 ? "unknown" : ""}`}>
                    <div className="bar inner-container" style={{width: `${this.props.percentage}%`}}></div>
                </div>

                {
                    this.props.currentStage ?
                    (
                    <div className="state">
                        <span>{this.props.filestate} {this.props.currentStageIndex}/{this.props.totalStages} - </span>
                        <span className="current-stage">{this.props.currentStage}</span>
                    </div>
                    ) :
                    (
                    <div className="state">
                        <span>{this.props.filestate}</span>
                    </div>  
                    )

                }
                
            </div>
        );
    }
}

export default ProgressBar;
export {setPercentage};