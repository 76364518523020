import decode from 'jwt-decode';

import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

class AuthHelperMethods {

    /**
     * Request login to application with given username and password
     *
     * @param {string} username
     * @param {string} password
     * @memberof AuthHelperMethods
     */
    login = (username, password) => {
        return this.fetch(`${REACT_APP_PROXY_SERVER}/auth/authenticate`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password
            })
        }).then(res => {
            this.setToken(res.token);
            return Promise.resolve(res);
        });
    };

    /**
     * Check whether or not the user is logged in with a valid token
     *
     * @returns {boolean}
     * @memberof AuthHelperMethods
     */
    loggedIn = () => {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    };

    /**
     * Checks whether or not the token is expired
     *
     * @param {string} token The token to check
     * @returns {boolean}
     * @memberof AuthHelperMethods
     */
    isTokenExpired = (token) => {
        try {
            const decoded = decode(token);
            return (decoded.exp < Date.now()/1000);
        } catch(err) {
            console.log("expired check failed");
            return false;
        }
    };

    /**
     * Sets the token item in the browser cache
     *
     * @param {string} idToken The token to be set
     * @memberof AuthHelperMethods
     */
    setToken = (idToken) => {
        localStorage.setItem('JWT', idToken);
    }

    /**
     * Gets the token item from the browser cache
     *
     * @returns {string} token
     * @memberof AuthHelperMethods
     */
    getToken = () => {
        return localStorage.getItem('JWT');
    }

    /**
     * Logs out of the application by removing the token item
     *
     * @memberof AuthHelperMethods
     */
    logout = () => {
        localStorage.removeItem('JWT');
    }

    /**
     * Gets decoded token if available
     *
     * @returns {string | false} token if available or false otherwise
     * @memberof AuthHelperMethods
     */
    getConfirm = () => {
        let answer = decode(this.getToken());
        return answer;
    }

    /**
     * Sends request with appropriate authorization headers if needed
     *
     * @param {string} url
     * @param {*} options
     * @returns {Promise} request promise
     * @memberof AuthHelperMethods
     */
    fetch = (url, options) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if (this.loggedIn()) {
            headers['Authorization'] = `Bearer ${this.getToken()}`;
        }

        return fetch(url, {
                    headers,
                    ...options
                })
                .then(this._checkStatus)
                .then(response => response.json());

    }

    /**
     * Checks response status
     *
     * @param {Response} response
     * @returns {Response | Error}
     * @memberof AuthHelperMethods
     */
    _checkStatus = (response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

}

export default AuthHelperMethods;