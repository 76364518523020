export const REACT_APP_PROXY_SERVER = getEnvVar('{REACT_APP_PROXY_SERVER}', process.env.REACT_APP_PROXY_SERVER);
export const REACT_APP_AUTH_TYPE = getEnvVar('{REACT_APP_AUTH_TYPE}', process.env.REACT_APP_AUTH_TYPE);


/**
 * When in develop mode, read env vars normally.
 * When in production mode, output a special string that will be replaced by script.
 *
 * @param {string} envVarStr the env var name
 * @param {string} processVar the default env var value
 * @return {string} the env var value
 */
function getEnvVar(envVarStr, processVar) {
    const isProd = process.env.NODE_ENV === 'production';
    const envVar = envVarStr.replace(/[{}]/g, '');
    return isProd ? (envVar || processVar) : processVar;
}