import React from 'react';
import {Redirect} from 'react-router-dom';

import GoogleButton from 'react-google-button';

import AuthHelperMethods from './AuthHelperMethods';

import './Login.scss';
import mainLogo from '../../constants/images/main_logo.svg';
import iconLogo from '../../constants/images/icon_logo.svg';
import { REACT_APP_AUTH_TYPE, REACT_APP_PROXY_SERVER } from '../../constants/envVars';

class Login extends React.Component {

    /**
     * Creates an instance of Login.
     * @param {*} props
     * @memberof Login
     */
    constructor(props) {
        super(props);
        this.state = {
            userAuthenticated: false,
            loginError: false,
            loginPending: false,
            username: '',
            password: '',
        };
        this.Auth = new AuthHelperMethods();
    }

    /**
     * React method triggered when component is mounted to DOM
     * Redirects user if already logged in
     *
     * @memberof Login
     */
    componentDidMount() {
        if(this.Auth.loggedIn()) {
            this.props.history.replace('/home');
        }
    }

    /**
     * Event handler for new input
     * Sets the username and password for login
     *
     * @param {*} event
     * @memberof Login
     */
    handleInputChange(event) {
        this.setState({
            loginError: false,
        });
        const {value, name} = event.target;
        this.setState({
            [name]: value,
        });
    }

    /**
     * Attempts to login to application
     *
     * @param {*} event A form submission event
     * @memberof Login
     */
    handleFormSubmit(event) {
        event.preventDefault();
        this.setState({
            loginPending: true,
            loginError: false,
            userAuthenticated: false,
        });
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                this.setState({
                    userAuthenticated: true,
                    loginPending: false,
                    loginError: false,
                });
                this.props.history.replace('/home');
            })
            .catch(err => {
                this.setState({
                    userAuthenticated: false,
                    loginPending: false,
                    loginError: true
                });
            });
    }

    handleGoogleRedirect(event) {
        event.preventDefault();
        window.open(`${REACT_APP_PROXY_SERVER}/auth/authenticate`, "_self");
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof Login
     */
    render() {
        return (
            <div className="login-background">
                <div className="content">
                    {
                    this.state.userAuthenticated ?
                    (<Redirect to="/home"/>) : null
                    }

                    <img src={iconLogo} className="icon-logo" alt="logo" />
                    <div className="product-name">Zero trust Integrated Platform</div>

                    {
                        REACT_APP_AUTH_TYPE === "googleOauth" ?
                        <GoogleButton className="button google-button" type="light" onClick={this.handleGoogleRedirect}></GoogleButton> :
                        (
                        <form className="form" onSubmit={this.handleFormSubmit.bind(this)}>
                            <div><input type="text" id="email" name="username" placeholder="Username" value={this.state.username} onChange={this.handleInputChange.bind(this)} required></input></div>
                            <div><input type="password" id="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange.bind(this)} required></input></div>
                            <div><input type="submit" className="button" value="SIGN IN"></input></div>
                        </form>
                        )
                    }

                    {
                    this.state.loginError ?
                    (<div className="message error-message">Incorrect username or password</div>) : null
                    }
                    {
                    this.state.loginPending ?
                    (<div className="message spinner"></div>) : null
                    }
                </div>
                <div className="login-footer">
                    <img src={mainLogo} className="main-logo" alt="logo"/>
                </div>
            </div>
        );
    }
}

export default Login;