import React from 'react';
import { NavLink } from 'react-router-dom';

import './AccountMenu.scss';
import userIcon from '../../constants/images/user_icon.svg';
import AuthHelperMethods from '../Login/AuthHelperMethods';


class AccountMenu extends React.Component {

    /**
     * Creates an instance of AccountMenu.
     * @param {*} props
     * @memberof AccountMenu
     */
    constructor(props) {
        super(props);
        this.state = {
            isAccountMenuOpen: false,
            name: "",
            username: "",
        };

        this.Auth = new AuthHelperMethods();
        let confirm = this.props.confirm;
        if (confirm) {
            this.state["name"] = `${confirm.firstName} ${confirm.lastName}`;
            this.state["username"] = confirm.username;
        }

        this.wrapperRef = React.createRef();
    }

    /**
     * React method triggered when component is mounted to DOM
     * Sets up handle for when outside of menu is click
     *
     * @memberof AccountMenu
     */
    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside.bind(this), false);
    }

    /**
     * React method triggered when component is unmounted from DOM
     * Cancels event handler
     *
     * @memberof AccountMenu
     */
    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside.bind(this), false);
    }

    /**
     * Helper function to close menu when outside click is detected
     *
     * @param {*} event
     * @memberof AccountMenu
     */
    handleClickOutside(event) {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.toggleAccountMenuDropdown(false);
        }
    }

    /**
     * Helper function to open settings
     *
     * @memberof AccountMenu
     */
    handleSettingsClick() {
        this.props.toggleSettings(true);
        this.toggleAccountMenuDropdown(false);
    }

    /**
     * Initiate logout for application
     *
     * @memberof AccountMenu
     */
    _handleLogout() {
        this.Auth.logout();
        this.props.history.replace('/login');
    }


    /**
     * Toggle menu open and closed
     *
     * @memberof AccountMenu
     */
    toggleAccountMenuDropdown(open) {
        if (open === false) {
            this.setState({
                isAccountMenuOpen: false,
            });
        } else {
            this.setState({
                isAccountMenuOpen: open || !this.state.isAccountMenuOpen,
            });
        }
    }


    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof AccountMenu
     */
    render() {
        return (
            <div className="header-menu" ref={this.wrapperRef}>
                <img src={userIcon} className="user-icon icon header" alt="logo" onClick={this.toggleAccountMenuDropdown.bind(this)}/>
                {
                    this.state.isAccountMenuOpen
                    ? (
                    <div className="dropdown" >
                        <div className="arrow-up"></div>
                        <div className="dropdown-content">
                            <img src={userIcon} className="user-icon" alt="logo"/>
                            <div className="account-information">
                                <div className="name">{this.state.name}</div>
                                <div className="username">{this.state.username}</div>
                            </div>
                            <div className="options">
                                <div className="option" onClick={this.handleSettingsClick.bind(this)}><NavLink to="/settings">Settings</NavLink></div>
                                <div className="option" onClick={this._handleLogout.bind(this)}>Logout</div>
                            </div>
                        </div>
                    </div>
                    )
                    : (
                        null
                    )
                }
            </div>
        );
    }
}

export default AccountMenu;