import React from 'react';
import axios, { CancelToken } from 'axios';

import './ReceiveFiles.scss';
import downloadIcon from '../../constants/images/download.svg';
import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

import AuthHelperMethods from '../Login/AuthHelperMethods';
import ProgressBar, {setPercentage} from '../ProgressBar/ProgressBar';
import { bytesToSize } from '../../utils/common'

class ReceiveFiles extends React.Component {

    /**
     * Creates an instance of ReceiveFiles.
     * @param {*} props
     * @memberof ReceiveFiles
     */
    constructor(props) {
        super(props);

        this.state = {
            inProgressFiles: [],
            receivedFiles: [],
            downloadError: false,
        };

        this.Auth = new AuthHelperMethods();

        this.cancelToken = CancelToken.source();
    }

    /**
     * React method triggered when component is mounted to DOM
     * Initiates timer to poll for received files request
     *
     * @memberof ReceiveFiles
     */
    componentDidMount() {
        this.getFilesRequest();
        this.timer = setInterval(() => this.getFilesRequest(), 15000);
    }

    /**
     * React method triggered when component is unmounted from DOM
     * Cancels timer to poll for received files request
     *
     * @memberof ReceiveFiles
     */
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        this.cancelToken.cancel("Request cancelled");
    }

    /**
     * Request for all files that have been received
     *
     * @memberof ReceiveFiles
     */
    getFilesRequest() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/received`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };

        axios.get(requestPath, {...options, cancelToken: this.cancelToken.token,})
                .then(response => {
                    const files = response.data;
                    this.parseFiles(files);
                })
                .catch(err => {
                    console.log(err);
                });
    }

    /**
     * Helper method to split received response into files that are in progress vs already received
     *
     * @param {Array} files
     * @memberof ReceiveFiles
     */
    parseFiles(files) {
        let inProgress = [];
        let received = [];
        for (let file of files) {
            if(file.finished) {
                received.push(file);
            } else {
                inProgress.push(file);
            }
        }

        this.setState({
            inProgressFiles: inProgress,
            receivedFiles: received,
        });
    }

    /**
     * Helper method to render files that are being received as table rows
     *
     * @return {*}
     * @memberof ReceiveFiles
     */
    getInProgressFiles() {
        const inProgressFiles = this.state.inProgressFiles.slice();
        inProgressFiles.sort((a,b) => {
            return a.started.localeCompare(b.started);
        });

        const newdata = inProgressFiles.map( (file) => {
            return (
                <tr key={file.filename + " " + file.started}>
                    <td>{file.filename}</td>
                    <td>{file.username}</td>
                    <td>{file.started}</td>
                    <td className="status">
                        <ProgressBar stages={file.stages} filestate={file.state} {...setPercentage(file.stages)}></ProgressBar>
                        <span className="filesize">{bytesToSize(file.filesize)}</span>
                    </td>
                </tr>
            );
        });

        return newdata;
    }

    /**
     * Helper method to render files that are have been received as table rows
     *
     * @return {*}
     * @memberof ReceiveFiles
     */
    getReceivedFiles() {
        const receivedFiles = this.state.receivedFiles.slice();
        receivedFiles.sort((a,b) => {
            return b.finished.localeCompare(a.finished);
        });

        const newdata = receivedFiles.map( (file) => {
            return (
                <tr key={file.transferId}>
                    <td>{file.filename}</td>
                    <td>{file.username}</td>
                    <td>{file.started}</td>
                    <td>{file.finished}</td>
                    <td>
                        <span>{bytesToSize(file.filesize)}</span>
                        <span transferid={file.transferId} filename={file.filename} filesize={file.filesize} className="download" onClick={this.downloadFile.bind(this)}>
                            <span className="download-progress hidden"></span>
                            <img src={downloadIcon} className="download-icon icon" alt="logo"/>
                        </span>
                     </td>
                </tr>
            );
        });

        return newdata;
    }

    /**
     * Event handler to download file from backend server
     *
     * @param {*} event A click event
     * @memberof ReceiveFiles
     */
    downloadFile(event) {
        const download = event.currentTarget;
        const transferId = download.getAttribute("transferid");
        const fileName = download.getAttribute("filename");
        const fileSize = parseInt(download.getAttribute("filesize"));

        const requestPath = `${REACT_APP_PROXY_SERVER}/files/received/download/${transferId}`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`,
                'Content-Disposition': `attachment; filename="${fileName}"`
            },
            responseType: 'blob',
            onDownloadProgress: (progress) => {
                // Axios requests does not support "stream" response type (axios issue #479), and we can't send headers through a window redirect, so use axios download progress instead of native browser download
                // TODO: lose download state on re-navigate, can we display progress elsewhere?
                if (progress.loaded === fileSize) {
                    download.querySelector(".download-progress").classList.add("hidden");
                    download.querySelector(".download-icon").classList.remove("hidden");
                }
                else {
                    download.querySelector(".download-progress").innerHTML = `${Math.round(progress.loaded/fileSize * 100)}%`;
                }
            } 
        };

        download.querySelector(".download-progress").classList.remove("hidden");
        download.querySelector(".download-icon").classList.add("hidden");
        axios.get(requestPath, options)
                .then(response => {
                    const data = response.data;
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.append(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    const errMsg = `Could not download file ${fileName}`;
                    console.log(err);
                    this.setState({
                        downloadError: errMsg,
                    });
                });
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof ReceiveFiles
     */
    render() {
        return (
            <div className="receive-content">
                <div className="in-progress section">
                    <span>In Progress</span>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Sender</th>
                            <th>Transfer Initiated</th>
                            <th className="status">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.getInProgressFiles() }
                        </tbody>
                    </table>

                </div>
                <div className="received-files section">
                    <span>Received Files</span>
                    <table className="section-content">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Sender</th>
                            <th>Transfer Initiated</th>
                            <th>Transfer Completed</th>
                            <th>File Size</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.getReceivedFiles() }
                        </tbody>
                    </table>

                    {
                    this.state.downloadError ?
                    (<div className="message error-message">Download failed - {this.state.downloadError}</div>) : null
                    }
                </div>
            </div>
        );
    }
}

export default ReceiveFiles;