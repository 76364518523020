import React, { Component } from 'react';
import AuthHelperMethods from './AuthHelperMethods';

/**
 * Wraps React component to see if authenticated before proceeding
 * Redirects if not authenticated
 *
 * @param {Component} AuthComponent
 * @return {Component} auth wrapped component
 */
function withAuth(AuthComponent) {

    const Auth = new AuthHelperMethods();

    return class AuthWrapped extends Component {
        state = {
            confirm: null,
            loaded: false
        }

        /**
         * React method triggered when component is mounted to DOM
         * Checks to see if authenticated and then redirects if not
         *
         * @memberof LinkedAccounts
         */
        componentDidMount() {
            if(!Auth.loggedIn()) {
                this.props.history.replace('/login');
            } else {
                try {
                    const confirm = Auth.getConfirm();
                    console.log("confirmation is " + confirm);
                    this.setState({
                        confirm: confirm,
                        loaded: true
                    });
                } catch(err) {
                    console.log(err);
                    Auth.logout();
                    this.props.history.replace("/login");
                }
            }
        }

        /**
         * Renders new auth wrapped component if available
         *
         * @return {Component | null}
         */
        render() {
            if (this.state.loaded === true) {
                if (this.state.confirm) {
                    console.log("confirmed");
                    return (
                        <AuthComponent history={this.props.history} confirm={this.state.confirm} />
                    )
                } else {
                    console.log("not confirmed");
                    return null;
                }
            } else {
                return null;
            }
        }
    }
}

export default withAuth;