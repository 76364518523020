import React from 'react';
import { NavLink, Route, HashRouter } from 'react-router-dom';

import axios from 'axios';

import './Home.scss';
import mainLogo from '../../constants/images/main_logo.svg';
import iconLogo from '../../constants/images/icon_logo.svg';

import AccountMenu from '../AccountMenu/AccountMenu';
import SendFiles from '../SendFiles/SendFiles';
import ReceiveFiles from '../ReceiveFiles/ReceiveFiles';
import SendNewFiles from '../SendFiles/SendNewFiles';
import GeneralSettings from '../GeneralSettings/GeneralSettings';
import withAuth from '../Login/withAuth';
import LinkedAccounts from '../LinkedAccounts/LinkedAccounts';
import RecipientHosts from '../RecipientHosts/RecipientHosts';
import Dashboard from '../Dashboard/Dashboard';
import AuthHelperMethods from '../Login/AuthHelperMethods';

import { REACT_APP_PROXY_SERVER } from '../../constants/envVars';

class Home extends React.Component {

    /**
     * Creates an instance of Home.
     * @param {*} props
     * @memberof Home
     */
    constructor(props) {
        super(props);
        this.state = {
            settingsOpen: false,
            endpoints: [],
        };
        this.Auth = new AuthHelperMethods();
    }

    /**
     * React method triggered when component is mounted to DOM
     * Initiates request for available endpoints
     *
     * @memberof Home
     */
    componentDidMount() {
        this.getEndpointsRequest();
    }

    /**
     * Get configured endpoints for current user
     *
     * @memberof Home
     */
    getEndpointsRequest() {
        const requestPath = `${REACT_APP_PROXY_SERVER}/files/endpoints`;
        const accessString = this.Auth.getToken();
        const options = {
            params: {},
            headers: {
                Authorization: `Bearer ${accessString}`
            }
        };

        axios.get(requestPath, options)
                .then(response => {
                    this.setState({
                        endpoints: response.data,
                    });
                })
                .catch(err => {
                    console.log(err);
                });
    }

    /**
     * Set settings menu open or closed
     *
     * @param {*} open Whether or not the settings menu should be open, defaults to toggle
     * @memberof Home
     */
    toggleSettings(open) {
        if (open === false) {
            this.setState({
                settingsOpen: open,
            });
        } else {
            this.setState({
                settingsOpen: open || !this.state.settingsOpen,
            });
        }
    }

    /**
     * React render method in jsx
     *
     * @return {*}
     * @memberof Home
     */
    render() {
        return (
            <HashRouter>
            <div className="main">
                <div className="header">
                    <NavLink to="/" onClick={() => this.toggleSettings(false)}>
                        <img src={iconLogo} className="icon-logo" alt="logo" />
                        <span className="product-name">Zero trust Integrated Platform</span>
                    </NavLink>
                    <div className="account-menu">
                        <AccountMenu history={this.props.history} confirm={this.props.confirm} toggleSettings={this.toggleSettings.bind(this)}></AccountMenu>
                    </div>
                </div>

                <div className="menu">
                    <div className="options">
                        <div className="option" onClick={() => this.toggleSettings(false)}><NavLink to="/send">Send Files</NavLink></div>
                        <div className="option" onClick={() => this.toggleSettings(false)}><NavLink to="/receive">Receive Files</NavLink></div>
                        <div className={`option ${this.state.settingsOpen ? "open" : ""}`} onClick={this.toggleSettings.bind(this)}>Settings</div>
                        <div className={`option sub-option ${this.state.settingsOpen ? "visible" : "hidden"}`}><NavLink to="/settings">About</NavLink></div>
                        <div className={`option sub-option ${this.state.settingsOpen ? "visible" : "hidden"}`}><NavLink to="/linked-accounts">Linked Accounts</NavLink></div> {/* TODO only if admin*/}
                        <div className={`option sub-option ${this.state.settingsOpen ? "visible" : "hidden"}`}><NavLink to="/recipient-hosts">Recipient Hosts</NavLink></div> {/* TODO only if admin*/}
                    </div>

                    <img src={mainLogo} className="main-logo" alt="logo" />
                </div>
                <div className="main-content">
                    <Route path="/" exact
                        render={(props) => (
                            <Dashboard {...props} confirm={this.props.confirm} />
                        )}
                    />
                    <Route path="/send" component={() => <SendFiles endpoints={this.state.endpoints} />} />
                    <Route path="/send-new" component={() => <SendNewFiles endpoints={this.state.endpoints} />}/>
                    <Route path="/receive" component={ReceiveFiles} />
                    <Route path="/settings" 
                        render={(props) => (
                            <GeneralSettings {...props} confirm={this.props.confirm} />
                        )}
                    />
                    <Route path="/linked-accounts" component={LinkedAccounts} />
                    <Route path="/recipient-hosts" 
                        render={(props) => (
                            <RecipientHosts {...props} getNewEndpoints={this.getEndpointsRequest.bind(this)} />
                        )}
                    />
                </div>
            </div>
            </HashRouter>
        );
    }
}

export default withAuth(Home);