import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Home from './components/Home/Home'
import Login from './components/Login/Login'


const NoMatch = () => (
  <Redirect to="/home" />
);

class App extends Component {

  render() {
    return (
      <BrowserRouter>
      <div>
        <Switch>
          <Route path="/login" component={Login}/>
          <Route path="/home" component={Home}/>
          <Route path="*" component={NoMatch}/>
        </Switch>
      </div>
      </BrowserRouter>
    );
  }

}

export default App;
